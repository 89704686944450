/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/16
* @description
*   Verify.vue of WeTrade
*/
<template>
<PageWithHeader>
  <template #title>
    <t path="idproof_5">Proof of Identity</t>
  </template>
  <div class="px-16">
    <t as="p" path="idproof_2" class="mt-24">
      The proof of identity is required to withdraw cash.
      Qualified documents include tax card and passport, others are not accepted.
      The name on the document must be consistent with the beneficiary name of the withdrawal.
      Please make sure that the uploaded photos are clear and legible.
    </t>
    <div class="t-center">
      <div class="preview">
        <Pic v-if="img" :src="img" style="width: 100%;height: 100%" />
        <Icon v-else name="plus" class="anchor" @click="upload" />
      </div>
      <Button icon="camera" class="upload f-bold f-lg br-sm" style="height: 32px;"
        @click="upload" :progress="progress">
        <t path="idproof_3" custom #="{td}">{{td || 'Upload'}}</t>
      </Button>
    </div>
  </div>
  <template #footer>
    <Button class="block primary f-bold f-lg" @click="confirm">
      <t path="idproof_4" custom #="{td}">{{td || 'Submit'}}</t>
    </Button>
  </template>
</PageWithHeader>
</template>

<script lang="ts">
import { uploadID } from '@/common/jsBridge.api'
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { showAlert } from '@/components/popup/popup'
import useRequest from '@/hooks/useRequest'
import { translate } from '@/i18n'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { goonTask, removeTask } from 'essential/task/task.temp'
import { defineComponent, onBeforeUnmount, shallowRef } from 'vue'
import { useRoute, useRouter } from 'vue-router'

enum Side {
  FRONT = 1,
  BACK
}

export default defineComponent({
  name: 'Verify',
  components: { Pic, Icon, Button, PageWithHeader },
  setup () {
    const query = useRoute().query
    const taskId = query.pendingTaskId as string
    const router = useRouter()
    const img = shallowRef(query.url as string)
    const [commit, progress] = useRequest(uploadID)

    const upload = () => {
      commit({ type: Side.FRONT }).then(resp => {
        img.value = (resp as { url: string }).url
      }).catch(() => {
        progress.value = false
      })
    }

    onBeforeUnmount(() => {
      removeTask(taskId)
    })

    const confirm = () => {
      // const autoForward = route?.query?.af as string
      // if (autoForward) {
      //   return router.replace({ name: autoForward })
      // } else
      if (!img.value) {
        showAlert(translate('toast_14'))
      } else {
        if (taskId) {
          goonTask(taskId)
        }
        router.back()
      }
    }

    return {
      img,
      upload,
      progress,
      confirm,
    }
  },
})
</script>

<style scoped>
.preview {
  margin: 24px auto;
  width: 215px;
  height: 128px;
  border: 1px solid var(--color-grey);
}

.anchor {
  padding: 40px 0;
  color: var(--color-grey);
  font-size: 40px;
}

.upload {
  width: 167px;
  color: var(--color-primary);
  background: var(--color-primary-thin);
}
</style>


import { uploadID } from '@/common/jsBridge.api'
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { showAlert } from '@/components/popup/popup'
import useRequest from '@/hooks/useRequest'
import { translate } from '@/i18n'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { goonTask, removeTask } from 'essential/task/task.temp'
import { defineComponent, onBeforeUnmount, shallowRef } from 'vue'
import { useRoute, useRouter } from 'vue-router'

enum Side {
  FRONT = 1,
  BACK
}

export default defineComponent({
  name: 'Verify',
  components: { Pic, Icon, Button, PageWithHeader },
  setup () {
    const query = useRoute().query
    const taskId = query.pendingTaskId as string
    const router = useRouter()
    const img = shallowRef(query.url as string)
    const [commit, progress] = useRequest(uploadID)

    const upload = () => {
      commit({ type: Side.FRONT }).then(resp => {
        img.value = (resp as { url: string }).url
      }).catch(() => {
        progress.value = false
      })
    }

    onBeforeUnmount(() => {
      removeTask(taskId)
    })

    const confirm = () => {
      // const autoForward = route?.query?.af as string
      // if (autoForward) {
      //   return router.replace({ name: autoForward })
      // } else
      if (!img.value) {
        showAlert(translate('toast_14'))
      } else {
        if (taskId) {
          goonTask(taskId)
        }
        router.back()
      }
    }

    return {
      img,
      upload,
      progress,
      confirm,
    }
  },
})
